'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { SimplePaginationList } from '@/components/utility/pagination/simple-pagination-list'
import { PaginationRepeater } from '@/components/utility/pagination/pagination-list'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import { type Category } from '@/lib/generated/graphql'

const categories = [
  'Furniture',
  'Home & Garden',
  'Beauty',
  'Alcohol',
  'Pets',
  'Toys & Games',
  'Health',
  'Hardware',
  'Sports & Outdoor',
  'Hobbies',
  'Electronics',
  'Commercial',
  'Fashion',
  'Groceries',
  'Baby',
  'Automotive',
  'Jewellery',
  'Photography',
  'Office',
  'Entertainment',
] as string[]

const links = [
  { label: 'Home', link: '/' },
  {
    label: 'Black Friday Sale',
    link: routeCalculatorRelative.categoriesRoute({
      category_level1: 'Black Friday Sales',
    } as unknown as Category),
  },
  ...categories.map((category) => ({
    label: category,
    link: routeCalculatorRelative.categoriesRoute({
      category_level1: category,
    } as unknown as Category),
  })),
]

export const CategoryBar = () => {
  const pathname = usePathname()

  return (
    <div className='w-screen bg-primary-dark'>
      <SimplePaginationList containerClass='min-w-[auto] mx-auto px-2 sm:px-4 pt-1 pb-2'>
        <PaginationRepeater rows={1}>
          {links.map(({ label, link }, i) => (
            <Link
              key={label}
              href={link}
              className={`relative whitespace-nowrap pb-1 text-sm  text-primary-contrast-text transition-colors hover:text-primary-contrast-text/80 ${pathname === link && 'after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 after:bg-secondary'} ${i === 1 ? 'font-bold' : 'font-medium'}`}
            >
              {label}
            </Link>
          ))}
        </PaginationRepeater>
      </SimplePaginationList>
    </div>
  )
}
